import Swal from "sweetalert2";
import party from "party-js";

window.showAchievementNotification = function(title, text) {
  Swal.fire({
    title,
    text,
    icon: "success",
    didOpen: function(e) {
      party.confetti(e, {
        count: party.variation.range(40, 60),
      });
    }
  });
}
